
@font-face {
  font-family: Abel-regular;
  src: local('Abel-regular'), url(./fonts/Abel/Abel-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Jakarta-extra;
  src: local('Jakarta-extra'), url(./fonts/plus_jakarta_plus/PlusJakartaSans-ExtraBold.ttf) format('truetype');
}


@font-face {
  font-family: Jakarta-regular;
  src: local('Jakarta-regular'), url(./fonts/plus_jakarta_plus/PlusJakartaSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Jakarta-semi;
  src: local('Jakarta-semi'), url(./fonts/plus_jakarta_plus/PlusJakartaSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: Jakarta-medium;
  src: local('Jakarta-medium'), url(./fonts/plus_jakarta_plus/PlusJakartaSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Jakarta-bold;
  src: local('Jakarta-bold'), url(./fonts/plus_jakarta_plus/PlusJakartaSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: Montserrat;
  src: local('Jakarta-bold'), url(./fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}
*{
  font: inherit;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

:target {
  scroll-margin-block: 5ex;
}

img,
picture {
  max-width: 100%;
  display: block;
}

body{
  margin: 0;
  overflow-x: hidden;
  min-height: 100vh;
  line-height: 1.5;
}
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
@keyframes bounce {
  from {
      transform: translate3d(0, 0, 0);
  }

  to {
      transform: translate3d(20px, 0, 0);
  }
}
.home-container{
  height: auto;
  margin-top: 12vh;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  padding-top: 30px;
  background-color: #FFFFFF;
}
.home-wrap{
  width: 90%;
  background: url('./background/home.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-container{
  width: 100%;
  display: flex;
  justify-content: center;
}
.m-wrap{
  width: 1000px;
  box-shadow: 0px 20px 60px 0px #edecec;
  padding-top: 10px;
  padding-bottom: 20px;
}
.m-header{
  font-family: Jakarta-bold;
  font-size: 64px;
  width: fit-content;
  text-align: center;
  background: linear-gradient(to right, #8407CC, #420466);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

}
.m-desc{
  width: 100%;
  font-family: Jakarta-medium;
  font-size: 18px;
  color: #55555C;
  text-align: center;
  margin-top: 30px;
}
.mobile-home-desc{
  display: none;
  width: 100%;
  font-family: Jakarta-medium;
  font-size: 16px;
  color: #55555C;
  text-align: center;
  margin-top: 10px;
}

.user-container{
  position: relative;
  align-items: center;
  display: flex;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 50px;
}
.badge-wrap{
  display: flex;
  height: fit-content;
}
.badge{
  height: 76px;
  width: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F3;
  border-radius: 12px;
}
.badge-icons{
  position: relative;
  display: flex;
}
.bicon-1{
  width: 38px;
  height: 38px;
  margin-left: -15px;
}
.bicon-1 img{
  width: 100%;
  height: 100%;
}
.bicon-2{
  margin-left: -15px;
  width: 38px;
  height: 38px;
}
.bicon-2 img{
  width: 100%;
  height: 100%;
}
.bicon-3{
  margin-left: -15px;
  width: 38px;
  height: 38px;
}
.bicon-3 img{
  width: 100%;
  height: 100%;
}
.bicon-4{
  margin-left: -15px;
  width: 38px;
  height: 38px;
}
.bicon-4 img{
  width: 100%;
  height: 100%;
}
.bicon-5{
  margin-left: -15px;
  width: 38px;
  height: 38px;
}
.bicon-5 img{
  width: 100%;
  height: 100%;
}
.badge-numb{
  width: 54px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-family: Jakarta-extra;
  margin-left: 20px;
}
.badge-text-wrap{
  height: 76px;
  margin-left: 30px;
  width: fit-content;
}
.bt-1{
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: 18px;
  font-family: Jakarta-medium;
  color: #55555C;
}
.bt-2{
  height: 38px;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: Jakarta-semi;
  color: #000000;
}
.badge-text{
}
.sales-btn-wrap{
  display: flex;
  width: fit-content;
  position: absolute;
  right: 0;
}
.main{

  height: 527px;
  width: 340px;
}
.main img{
}
.white{
  height: 250px;
  width: 400px;
  position: absolute;
  left: -120px;
  top: 30px;
}
.white img{

}
.green{
  left: -100px;
  height: 250px;
  width: 500px;
  position: absolute;
  bottom: 10px;
}
.green img{

}
.purple{
  height: 300px;
  width: 400px;
  position: absolute;
  right: -80px;
  bottom: 5px;
}
.purple img{

}

.cc{
  height: 300px;
  width: 400px;
  position: absolute;
  top: 20px;
  right: -180px;
}
.cc img{
}
.sales-btn{

}
.nobile-home-img{
  display: none;
}

.home-cards-wrap{
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  height: 527px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 100px;
  background-color: #FFFFFF;
}
.about-container{
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-container{

  width: 100%;
  display: flex;
  justify-content: center;
}
.sub-wrap{

  width: 90%;
}
.about{
  width: 100px;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-container{
  height: 12vh;
  width: 100%;
  display: flex;
  flex: 5;
  position: fixed;
  z-index: 4;
  background-color: #FFFFFF;
  top: 0;
  padding-left: 5%;
  padding-right: 5%;
}
.echo{
  flex: 1;
  height: inherit;
  display: flex;
}
.logo{
  height: inherit;
  display: flex;
  align-items: center;
}
.logo img{
  width: 50px;
  height: 50px;
}
.echo-text{
  height: inherit;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 22px;
  color: #8407CC;
  margin-left: 15px;
  font-family: Jakarta-semi;

}
.nav-list{
  flex: 3;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}
.item{
  height: inherit;
  display: flex;
  align-items: center;
  width: fit-content;
  color: #55555C;
  font-size: 16px;
  width: fit-content;
  margin-right: 50px;
  font-family: Jakarta-regular;
}
.nav-button{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: default;
}
.btn-1{
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  background-color:#8407CC;
  color: #FFFFFF;
  font-size: 16px;
  font-family: Jakarta-bold;
}

.btn-2{
  background-color:#FAFAFA;
  width: 50px;
  height: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 24px;
  border-radius: 100%;
  font-family: Jakarta-bold;
}

.card-container{

}
.choose-container{
  height: 600px;
  display: flex;
  justify-content: center;
  cursor: default;
  padding-bottom: 50px;
}
.choose-wrap{
  width: 90%;
  margin-top: 80px;

}
.choose-header{
  width: 100%;
  text-align: center;
}
.choose-main{
  width: 100%;
  text-align: center;
  font-family: Jakarta-bold;
  font-size: 32px;
  color: #000000;
}
.choose-desc{
  font-family: Jakarta-semi;
  font-size: 26px;
  color: #0000FF;
  margin-top: 10px;
}
.choose-c-wrap{
  display: flex;
  margin-top: 50px;
}
.choose-c-1{
  margin-right: 20px;
  width: 406px;
  border: 2px solid #F2F4F7;
  height: 345px;
  padding-left: 20px;
  padding-top: 30px;
  border-radius: 12px;
}
.choose-c-2{
  margin-right: 20px;
  width: 406px;
  border: 2px solid #F2F4F7;
  height: 345px;
  padding-left: 20px;
  padding-top: 30px;
  border-radius: 12px;
}
.choose-c-3{
  border: 2px solid #F2F4F7;
  width: 406px;
  height: 345px;
  padding-left: 20px;
  padding-top: 30px;
  border-radius: 12px;
}
.choose-c-icon{
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background-color: #F9F0FE;
  display: flex;
  align-items: center;
  justify-content: center;
}
.choose-c-icon img{
  width: 30px;
  height: 30px;
}
.choose-c-header{
  font-family: Jakarta-semi;
  font-size: 24px;
  color: #022623;
  margin-top: 20px;
}

.choose-c-desc{
  font-family: Jakarta-regular;
  font-size: 18px;
  color: #808080;
  line-height: 180%;
  margin-top: 20px;
  width: 350px;
}
.pay-container{
  height: 300px;
  background-image: linear-gradient(#7006AD, #420466);
  padding-bottom: 10px;
  padding-top: 20px;
}
.pay-wrap{
  position: relative;
  padding-left: 2.5%;
  height: 280px;
  padding-right: 2.5%;
}
.pay-btn-wrap{
  height: 100px;
  display: flex;
  align-items: center;
}
.pay-btn{
  width: 91px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-family: Jakarta-medium;
  font-size: 14px;
  border: 1px solid #9008DE;
  color: #9008DE;

}
.pay-header{
  width: 50%;
  font-family: Jakarta-semi;
  font-size: 42px;
  margin-top: 0px;
  color: #FFFFFF;
}
.pay-icons{
  position: absolute;
  right: 0;
  width: 50%;
  top: 0;
  height: 277px;
  padding-right: 2.5%;
}
.pay-1{
  display: flex;
  height: fit-content;
  position: relative;
  align-items: center;
  height: 130px;
}
.pay-2{
  display: flex;
  position: relative;
  height: 130px;
  justify-content: center;
  padding-top: 10px;
}
.sp{
  width: 256px;
  height: 80px;
  position: absolute;
  left: 0;
  top: 10px;
}
.sp img{
  width: 100%;
  height: 100%;
}

.yt{
  width: 15vw;
  height: 58px;
  margin-right: 20px;
  position: absolute;
  left: 18vw;
  margin-left: 30px;
}
.yt img{
  width: 100%;
  height: 100%;
}

.app{
  width: 114px;
  height: 114px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.app img{
  width: 100%;
  height: 100%;
}
.gram{
  width: 10vw;
  height: 128px;
  position: absolute;
  bottom: 0;
  left: 100px;
}
.gram img{
  width: 100%;
  height: 100%;
}
.flix{
  width: 12vw;
  height: 48px;
  position: absolute;
  left: 18vw;
}
.flix img{
  width: 100%;
  height: 100%;
}
.angle{
  width: 104px;
  height: 112px;
  position: absolute;
  right: 120px;
}
.angle img{
  width: 100%;
  height: 100%;
}

.contact-container{
  height: 1150px;
  display: flex;
  justify-content: center;
  cursor: default;
}
.contact-wrap{
  width: 90%;
  height: fit-content;
  position: relative;
  padding-bottom: 50px;
}
.contact-header{
  text-align: center;
  font-family: Jakarta-semi;
  font-size: 30px;
  color: #000000;
}
.contact-desc{
  text-align: center;
  font-family: Jakarta-regular;
  font-size: 28px;
  color: #000000;
  line-height: 40px;
  margin-top: 20px;
  display: block;
}
.contact-team{
  /* border: 2px solid red; */
  height: fit-content;
  display: flex;
  flex: 2;
  margin-top: 50px;
  position: relative;
}
.team{
  flex: 1;
}
.t-step ul{
  padding-left: 30px;
}
.team-desc{
  margin-bottom: 50px;
  position: relative;
}
.desc-wrap{
  display: flex;
}
.team-num{

}
.t-1{
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #AAAAAD;
  font-family: Montserrat;
  font-size: 24px;
  background-color: #F2F2F3;
}
.team-text{
  align-items: center;
  margin-left: 40px;
  position: relative;

}
.side-margin{
  border: 1px solid #01C467;
  height: 80vh;
  position: absolute;
  left: 30px;
  z-index: -1;
  display: block;
}

.t-header{
  color: #CCCCCE;
  font-family: Jakarta-semi;
  font-size: 32px;
}
.t-text{}
.contact-form{
  flex: 1;
  position: relative;
  height: inherit;
}
.form-1{
  padding-left: 10%;
  padding-right: 10%;
  background-color: #FFFFFF;
  height: 95%;
  border: 1px solid #E3E3E3;
  margin-bottom: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.f-name{
  border: 2px solid #F1F1F4;
  width: 100%;
  height: 94px;
  border-radius: 8px;
  position: inherit;
  margin-bottom: 30px;
  background-color: #FCFCFD;
}

.f-message{
  border: 2px solid #F1F1F4;
  width: 100%;
  height: 200px;
  border-radius: 8px;
  position: inherit;
  padding-bottom: 20px;
  background-color: #FCFCFD;
}
.f-name-label{
  height: fit-content;
  width: fit-content;
  font-size: 16px;
  font-family: Jakarta-regular;
  color: #808080;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}
textarea{
  resize: none;
  height: 150px;
  width: inherit;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: hidden;
  border: none;
  outline: none;
  background-color: #FCFCFD;
}
.f-name-input{
  height: 50px;
  width: 100%;
}
.f-name-input input{
  width: 100%; 
  background-color: #FCFCFD;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
  font-size: 18px;
  font-family: Jakarta-regular;
  color: #000000;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  outline: none;
}
.contact-btn-wrap{
  display: flex;
  justify-content: center;
  height: fit-content;
  margin-top: 50px;
}
.contact-btn{
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: Abel-regular;
  border-radius: 30px;
  background-color: #8407CC;
  color: #FCFCFD;
}
.form-2{
  border-right: 2px solid #CCCACA;
  height: 100%;
  width: 600px;
  right: 0;
  position: absolute;
  border-radius: 0px 0px 0px 30px;
  background-color: #FAFAFA;
  z-index: -1;
  bottom: 0;
}
.t-desc{
  color: #CCCCCE;
  font-family: Jakarta-regular;
  font-size: 16px;
  margin-top: 20px;
  line-height: 30px;
}
.unlock-container{
  height: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 200px;
}
.unlock-wrap{
  width: 90%;
  display: flex;
}
.lock-1{
  width: 49.5%;
  height: 900px;
  border-radius: 64px;
  background-color: #6A06A3;
  color: #FFFFFF;
  padding-bottom: 50px;
  padding-top: 100px;
  margin-right: 1%;
}
.unlock-head{
  width: 505px;
  font-family: Jakarta-extra;
  font-size: 85px;
  padding-left: 30px;
}
.unlock-image-wrap{
  height: 327px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  height: 327px;
}
.u-image{
  width: 90%;
}
.u-image img{
  width: 100%;
  height: 100%;
}
.lock-2{
  width: 49.5%;
  height: 900px;
  border-radius: 64px;
  padding-top: 80px;
  background-color: #15191D;
}

.unlock-2-head{
  width: 100%;
  font-family: Jakarta-extra;
  font-size: 5vw;
  padding-left: 5%;
  padding-right: 5%;
  color: #FFFFFF;
}

.u-2-image{
  width: 554px;
}
.u-row-1{
  display: flex;
  width: 90%;
  padding-left: 30px;
}
.u-btn-1{
  width: 151.71px;
  height: 144.81px;
  border-radius: 40px;
  position: absolute;
  top: 0;
  left: 5%;
}
.u-btn-1 img{
  width: 100%;
  height: 100%;
}
.u-btn-2{
  width: 163.98px;
  height: 156.53px;
  border-radius: 40px;
  position: absolute;
  top: 0;
  right: 15vw;
}
.u-btn-3{
  width: 149.96px;
  height: 143.14px;
  border-radius: 40px;
  position: absolute;
  top: 0px;
  right: 5%;
}

.u-btn-4{
  width: 157.77px;
  height: 150.59px;
  border-radius: 40px;
  position: absolute;
  bottom: 0;
  left: 8%;
}
.u-btn-5{
  width: 156.17px;
  height: 149.07px;
  border-radius: 40px;
  position: absolute;
  left: 38%;
  bottom: 0;
}
.u-btn-6{
  width: 156.17px;
  height: 171.79px;
  border-radius: 40px;
  position: absolute;
  right: 6%;
  bottom: 0;
}


.u-row-2{
  display: flex;
  width: 90%;
  padding-left: 30px;
  margin-top: 5px;
}

.unlock-2-wrap{
  height: 327px;
  margin-top: 30px;
  /* display: flex; */
  justify-content: center;
  height: 327px;
  position: relative;
}
.bills-container{
  height: 1500px;
  display: flex;
  justify-content: center;
  padding-bottom: 200px;
}
.bills-wrap{
  width: 90%;
  height: 1500px;
}
.bills-header{
  width: fit-content;
  height: fit-content;
  font-family: Jakarta-extra;
  font-size: 8vw;
 
}
.native-wrap{
  display: flex;
  margin-top: 150px;
}
.n-1{
  width: 49.5%;
  margin-right: 1%;
  height: 900px;
  border-radius: 40px;
  padding-top: 60px;
  background-color: #F1F2F4;
}
.n-1-icon-wrap{
  display: flex;
  position: relative;
  height: 200px;
  margin-top: 100px;
}
.m-nav-container{
  position: fixed;
  top: -100vh;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background: #FFFFFF;
  transition: top 1s;
}
#slide-in{
  top: 0;   
}
.m-list{
  height: 50vh;
  margin-top: 100px;
}
.ml-1{
  width: 100%;
  font-size: 18px;
  text-align: center;
  height: 200px;
  margin-top: 200px;
  font-family: Jakarta-regular;
  border: 2px solid #FFFFFF;
}
.m-nav-wrap{
  width: 100%;
  height: inherit;
}
.n-icon-wrap-2{
  display: flex;
  position: relative;
  height: 200px;
}
.nf-icon{
  width: 116px;
  height: 110px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 20px;
}
.ng-icon{
  width: 127px;
  height: 120px;
  margin-right: 10px;
  position: absolute;
  left: 120px;
  top: 0;
}
.nt-icon{
  width: 114px;
  height: 108px;
  margin-right: 10px;
  position: absolute;
  right: 160px;
  bottom: 20px;
}
.ns-icon{
  width: 124px;
  height: 118px;
  margin-right: 10px;
  position: absolute;
  top: 10px;
  right: 30px;
}
.ny-icon{
  width: 131px;
  height: 125px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 30px;
}
.nm-icon{
  width: 120px;
  height: 114px;
  margin-right: 10px;
  position: absolute;
  left: 141px;
  top: -20px;
}
.ngram-icon{
  width: 114px;
  height: 114px;
  margin-right: 10px;
  position: absolute;
  right: 30px;
}
.nx-icon{
  width: 120px;
  height: 14=34px;
  margin-right: 10px;
  position: absolute;
  bottom: 0;
  right: 150px;
}
.n-1-head{
  font-family: Jakarta-extra;
  font-size: 90px;
  color: #56037C;
  padding-left: 5%;
}

.n-2-head{
  font-family: Jakarta-extra;
  font-size: 7vw;
  color: #56037C;
  padding-left: 5%;
  color: #FFFFFF;
}
.n-2{
  width: 49.5%;

  height: 900px;
  border-radius: 40px;
  padding-top: 120px;
  line-height: 120px;
  background-image: linear-gradient(#31FFB4, #56037C);
}
.c-card-container{
  height: 1100px;
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: column;
  padding-bottom: 100px;
  position: relative;
  padding-top: 50px;
}
.c-card-wrap{
  width: 100%;
  height: 1000px;
  position: relative;
  display: flex;
  margin-top: 50px;
  height: 700px;
}
.c-card-header{
  display: flex;
  justify-content: center;
  height: fit-content;
  font-family: Jakarta-semi;
  font-size: 42px;
}
.span-card{
  color: #C54000;
  margin-left: 10px;
}
.personalize{
  width: 450px;
  position: absolute;
  top: 330px;
}
.p-header{
  color: #00000A;
  font-family: Jakarta-semi;
  font-size: 28px;
  line-height: 45px;
}
.p-list-1-wrap{
  width: fit-content;
  display: flex;
  height: 30px;
  align-items: center;
  margin-top: 20px;
  margin-left: 30px;
}
.p-list-2-wrap{
  display: flex;
  height: 30px;
  align-items: center;
  width: fit-content;
  margin-left: 70px;
  margin-top: 15px;
}
.p-list-3-wrap{
  display: flex;
  height: 30px;
  align-items: center;
  width: fit-content;
  margin-left: 120px;
  margin-top: 15px;
}
.p-list-4-wrap{
  display: flex;
  height: 30px;
  align-items: center;
  width: fit-content;
  margin-left: 180px;
  margin-top: 15px;
}

.p-list-1{
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  color: #808084;
  font-family: Jakarta-regular;
  font-size: 16px;
  margin-left: 20px;
}
.p-list-icon{
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-list-icon img{
  width: 100%;
  height: 100%;
}
.p-list-2{
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  color: #808084;
  font-family: Jakarta-regular;
  font-size: 16px;
  margin-left: 20px;
}
.p-list-3{
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  color: #808084;
  font-family: Jakarta-regular;
  font-size: 16px;
  margin-left: 20px;
}
.p-list-4{
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  color: #808084;
  font-family: Jakarta-regular;
  font-size: 16px;
  margin-left: 20px;
  
}
.cards-container{
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: relative;
  height: 900px;
  width: 100%;
}
.cards{
  width: 62%;
  height: inherit;
  position: absolute;
  height: 711px;
  bottom: 0;
  top: 10px;
  right: 15vw;
}
.cards img{
  width: 100%;
  height: 100%;
}

.seamless{
  position: absolute;
  right: 0;
  top: 100px;
  width: 480px;
}

.s-header{
  color: #00000A;
  font-family: Jakarta-semi;
  font-size: 28px;
  line-height: 45px;
  display: block;
}
.s-list-1-wrap{
  width: fit-content;
  display: flex;
  height: 30px;
  align-items: center;
  margin-top: 20px;
  margin-top: 20px;
  margin-left: 30px;
}
.s-list-2-wrap{
  display: flex;
  height: 30px;
  align-items: center;
  width: fit-content;
  margin-left: 70px;
  margin-top: 15px;
}
.s-list-3-wrap{
  display: flex;
  height: 30px;
  align-items: center;
  width: fit-content;
  margin-left: 120px;
  margin-top: 15px;
}
.s-list-4-wrap{
  display: flex;
  height: 30px;
  align-items: center;
  width: fit-content;
  margin-left: 180px;
  margin-top: 15px;
}

.s-list-1{
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  color: #808084;
  font-family: Jakarta-regular;
  font-size: 16px;
  margin-left: 20px;
}
.s-list-icon{
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s-list-icon img{
  width: 100%;
  height: 100%;
}
.s-list-2{
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  color: #808084;
  font-family: Jakarta-regular;
  font-size: 16px;
  margin-left: 20px;
}
.s-list-3{
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  color: #808084;
  font-family: Jakarta-regular;
  font-size: 16px;
  margin-left: 20px;
}
.s-list-4{
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  color: #808084;
  font-family: Jakarta-regular;
  font-size: 16px;
  margin-left: 20px;
}


.contact-sales{
  width: 100%;
  height: 100px;
  margin-top: 50px;
  display: flex;
  justify-content: end;
}
.sales{
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background-color: #8407CC;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sales img{
  width: 50px;
  height: 20px;
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: infinite;
}
.c-s{
  height: 100px;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #000000;
  font-family: Jakarta-semi;
  margin-right: 20px;
}
.unlimited-container{
  height: 560px;
  background-color: #FAFAFA;
}
.unlimited-wrap{
  height: 460px;
  width: 100%;
  background-image: url('./background/unlimited.svg');
}
.u-desc-wrap{
  display: flex;
  justify-content: center;
  height: fit-content;
  text-align: center;
  padding-top: 80px;
  color: #FFFFFF;
}
.u-desc{
font-family: Jakarta-semi;
 font-size: 42px;
 width: 800px;
}
.u-btn-wrap{
  height: fit-content;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  cursor: default;
}
.u-btn{
  width: 145px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Jakarta-bold;
  background-color: #0000FF;
  color: #FFFFFF;
  border-radius: 8px;
}
.footer-container{
  height: auto;
  display: flex;
  justify-content: center;
  cursor: default;
  z-index: 1;
  padding-top: 50px;
}

.foot-wrap{
  width: 100%;
}

.echodydx{
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
}
.echo-header{
  display: flex;
  height: inherit;
}
.foot-logo{
  height: inherit;
  display: flex;
  align-items: center;
}
.echo-desc{
  height: inherit;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 20px;
  color: #8407CC;
  margin-left: 15px;
  font-family: Jakarta-semi;
}

.foot-logo img{
  width: 45px;
  height: 45px;
}
.foot-desc{
  display: flex;
  flex: 2;
  margin-top: 20px;
  padding-left: 5%;
  padding-right: 5%;
}
.desc-1{
  flex: 1;
  width: fit-content;
  color: #55555C;
  font-size: 18px;
  font-family: Jakarta-regular;
  letter-spacing: -2%;
  line-height: 30px;
}
.desc-wrap{
  width: 80%;
}
.social-icons{
  flex: 1;
  display: flex;
  justify-content: end;
}
.social{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-left: 10px;
  background-color: #F9F0FE;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social img{
  width: 20px;
  height: 20px;
}
.foot-service{
  margin-top: 100px;
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
}
.mobile-foot-service{
  display: none;
}
.service-header{
  font-family: Jakarta-bold;
  color: #000024;
  font-size: 18px;
  margin-bottom: 50px;
}
.service-desc{
  margin-right: 60px;
}
.service-1{
  color: #55555C;
  font-family: Jakarta-regular;
  font-size: 18px;
  margin-top: 20px;
}
.foot-trademark{
  border-radius: 8px;
  height: auto;
  padding: 20px;
  color: #55555C;
  font-family: Jakarta-regular;
  font-size: 18px;
  line-height: 30px;
  background-color: #FAFAFA;
  margin-top: 100px;
  margin-left: 5%;
  margin-right: 5%;
}
.foot-copyright{
  border-top: 2px solid #E5E5E6;
  height: 100px;
  margin-top: 100px;
  padding-left: 200px;
  display: flex;
  align-items: center;
  color: #55555C;
  font-family: Jakarta-regular;
}

#bg{
  background-color: #8407CC;
  color: #FFFFFF;
}

#active-h{
  color: #000000;
}
#active-desc{
  color: #808084;
}
.mobile-cards{
  display: none;
}
.mobile-home-img{
  display: none;
}
.mobile-cc{
  width: 100%;
  display: none;
  top: 150px;
}
@media screen and (max-width: 1020px) and (min-width:50px){
  body{
      margin: 0;
      padding: 0;
  }
  .logo img{
    width: 45px;
    height: 45px;
  }
  .echo-text{
    font-size: 18px;
    margin-left: 5px; 
  }
  .mobile-cc{
    display: block;
    position: absolute;
    height: 900px;
  }
  .home-container{
    padding-bottom: 10px;
  }
  .mobile-home-img{
    display: flex;
    justify-content: center;

  }

  .btn-1{
    margin-right: 10px;
    width: 107px;
    height: 35px;
    border-radius: 7px;
    font-size: 11px;
  }

  .btn-2{
    display: flex;
  }

  .nav-list{
    display: none;
  }
  .home-wrap{
    width: 95%;
    background: none;
  }
  .m-wrap{
    width: 100%;
    box-shadow: none;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .m-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .m-header{
    font-size: 26px;
  }
  .m-desc{
    width: 100%;
    font-family: Jakarta-medium;
    font-size: 16px;
    color: #55555C;
    text-align: center;
    margin-top: 30px;
    display: none;
  }
  .mobile-home-desc{
    display: block;
    margin-top: 20px;
  }
  .mobile-desc{
    display: none;
  }
  .user-container{
    display: none;
  }
  .home-cards-wrap{
    display: none;
  }
  .nobile-home-img{
    display: flex;
    height: 530px;
    width: 340px;
  }
  .foot-desc{
    flex-direction: column;
    padding-left: 0%;
    padding-right: 0%;
    
  }
  .desc-wrap{
    width: 100%;
  }

  .foot-service{
    display: none;
  }
  .mobile-foot-service{
    display: block;
    margin-top: 50px;
  }
  .service-mobile{
    display: flex;
  }
  .service-desc{
    margin-right: 0px;
    margin-top: 30px;
    width: 50%;
  }
  .service-desc-t{
    width: 100%;

  }
  .service-1{
    font-size: 16px;

  }
  .service-header{
    font-size: 16px;
    margin-bottom: 20px;
  }
  .service-desc-m{
    margin-top: 30px;
    width: 50%;
    padding-left: 50px;
  }

  .foot-copyright{
    padding-left: 0px;
    justify-content: center;
  }

  .contact-team{
    flex-direction: column;
  }
  .unlock-wrap{
    flex-direction: column;
  }
  .choose-c-wrap{
    flex-direction: column;
  }

  .lock-1{
    width: 100%;
  }
  .lock-2{
    width: 100%;
  }
  .native-wrap{
    flex-direction: column;
    margin-top: 50px;
  }
  .n-1{
    width: 100%;
    height: 555px;
  }
  .n-1-head{
    font-size: 55px;
    padding-left: 5%;
    line-height: 60px;
  }
  
  .n-2-head{
    font-family: Jakarta-extra;
    font-size: 54px;
    color: #56037C;
    padding-left: 5%;
    color: #FFFFFF;
    line-height: 70px;
  }

  .n-2{
    width: 100%;
    height: 555px;
    margin-top: 50px;
  }
  .mobile-cards{
    display: flex;
    height: auto;
    margin-top: 100px;
    width: 100%;
  }
  .mobile-cards-wrap{
    height: inherit;
    width: 100%;
  }
  .m1{
    width: 100%;
    height: 260px;
  }
  .m1 img{
    width: 100%;
    height: 100%;
  }
  .pay-container{
    display: none;
  }
  .cards-container{
    display: none;
  }


  .p-list-1-wrap{
    margin-left: 0px;
  }
  .p-list-2-wrap{
    margin-left: 0px;
  }
  .p-list-3-wrap{
    margin-left: 0px;
  }
  .p-list-4-wrap{
    margin-left: 0px;
  }

  .s-list-1-wrap{
    margin-left: 0px;
  }
  .s-list-2-wrap{
    margin-left: 0px;
  }
  .s-list-3-wrap{
    margin-left: 0px;
  }
  .s-list-4-wrap{
    margin-left: 0px;
  }

  .seamless{
    left: 0;
    top: 0px;
    height: fit-content;
    width: 100%;
  }
  .contact-sales{
    display: none;
  }
  .contact-container{
    height: auto;
  }
  .c-card-header{
    width: 100%;
    font-size: 34px;
  }
  .span-card{
    color: #C54000;
  }
  .s-header{
    display: none;
  }
  .side-margin{
    display: none;
  }
  .team{
  }
  .bills-container{
    height: auto;
  }
  .bills-wrap{
    height: auto;
    width: 95%;
  }
  .bills-header{
    font-size: 60px;

  }
  .contact-desc{
    display: none;
  }

  .contact-wrap{
    width: 95%;
  }
  .contact-header{
    text-align: start;
    font-size: 52px;
    line-height: 60px;
  }
  .desc-wrap{
  }
  .t-header{
    font-size: 24px;
  }

  .team-text{
    align-items: center;
    margin-left: 10px;
    position: relative; 
  }
  .t-desc{
    font-size: 15px;
  }
  .t-step ul{
    padding-left: 20px;
  }

  .unlimited-container{
    height: 1010px;
    padding-top: 0px;
  }
  .unlimited-wrap{
    height: inherit;
    width: 100%;
    background-image: url('./background/mobile-bg.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 50px;
  }

  .u-desc{
      font-family: Jakarta-semi;
     font-size: 28px;
     width: 90%;
     text-align: start;
    }

    .u-btn-wrap{
      height: fit-content;
      display: flex;
      justify-content: center;
      margin-top: 310px;
    }

    .p-header{
      font-size: 24px;
      line-height: 40px;
    }
    .footer-container{
    }
    .desc-1{
      flex: 1;
      width: fit-content;
      color: #55555C;
      font-size: 13px;
      font-family: Jakarta-regular;
      letter-spacing: -2%;
      line-height: 20px;
    }
    .desc-wrap{
      width: 100%;
    }
    .social-icons{
      justify-content: start;
      margin-top: 20px;
    }

    .social{
      width: 50px;
      height: 50px;
      margin-left: 0px;
      margin-right: 20px;
      margin-top: 20px;
    }
    .social img{
      width: 25px;
      height: 25px;
    }
    .foot-wrap{
      width: 95%;
    }
    .foot-trademark{
      font-size: 12px;
      line-height: 20px;
    }
    .echo-header{
    }
    .echodydx{
      padding-left: 0%;
      padding-right: 0%;
    }

    .choose-container{
      height: auto;
    }
    .choose-wrap{
      width: 95%;
      margin-top: 80px;
    
    }
    .choose-header{
      width: 100%;
    }
    .choose-c-1{
      margin-right: 0px;
      width: 100%;
      height: 260px;
      padding-left: 10px;
      padding-right: 10px;

    }
    .choose-c-2{
      margin-right: 0px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      height: 260px;
      margin-top: 20px;
    }
    .choose-c-3{
      border: 2px solid #F2F4F7;
      width: 100%;
      height: 345px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 12px;
      height: 260px;
      margin-top: 20px;
    }

    .choose-main{
      font-size: 20px;
    }
    .choose-desc{
      font-family: Jakarta-semi;
      font-size: 16px;
    }
    .choose-c-desc{
      font-family: Jakarta-regular;
      font-size: 14px;
      color: #808080;
      line-height: 180%;
      margin-top: 20px;
      width: 100%;
    }
    .choose-c-header{
      font-family: Jakarta-semi;
      font-size: 20px;
      color: #022623;
      margin-top: 20px;
    }

    .choose-c-icon{
      width: 44px;
      height: 44px;
      border-radius: 8px;
      background-color: #F9F0FE;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .choose-c-icon img{
      width: 22px;
      height: 22px;
    }
    .n-1-icon-wrap{
      display: flex;
      position: relative;
      height: 120px;
      margin-top: 100px;
    }
    
    .n-icon-wrap-2{
      display: flex;
      position: relative;
      height: 120px;
    } 
    .nf-icon{
      width: 63px;
      height: 59px;
      top: 0;
    }
    .ng-icon{
      width: 80px;
      height: 76px;
      top: -10px;
      left: 80px;
    }
    .nt-icon{
      width: 72px;
      height: 68px;
      margin-right: 10px;
      position: absolute;
      right: 82px;
      bottom: 0;
    }
    .ns-icon{
      width: 72px;
      height: 68px;
      margin-right: 10px;
      position: absolute;
      top: 0;
      right: 0;
    }
    .ny-icon{
      width: 76px;
      height: 71px;
      margin-right: 10px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .nm-icon{
      width: 75px;
      height: 71px;
      margin-right: 10px;
      position: absolute;
      left: 82px;
      top: -20px;
    }
    .ngram-icon{
      width: 72px;
      height: 72px;
      margin-right: 10px;
      position: absolute;
      right: 0;
    }
    .nx-icon{
      width: 75px;
      height: 84px;
      margin-right: 10px;
      position: absolute;
      bottom: 0;
      right: 82px;
    }
    .form-1{
      padding-left: 0%;
      padding-right: 0%;
      background-color: #FFFFFF;
      height: 95%;
      border: none;
      margin-bottom: 20px;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .form-2{
      display: none;
    }
    .unlock-container{
      height: auto;
      display: flex;
      justify-content: center;
      padding-bottom: 50px;
      padding-top: 0px;
    }
    .unlock-wrap{
      width: 95%;
      display: flex;
    }
    .lock-1{
      width: 100%;
      height: 555px;
      border-radius: 40px;
      background-color: #6A06A3;
      color: #FFFFFF;
      padding-bottom: 50px;
      padding-top: 50px;
      margin-right: 0%;
      padding-left: 5%;
      padding-right: 5%;
    }
    .unlock-head{
      width: 100%;
      font-family: Jakarta-extra;
      font-size: 55px;
      padding-left: 0px;
    }

    .unlock-image-wrap{
      height: 200px;
      margin-top: 20px;
    }
    .u-image{
      width: 100%;
    }

    .lock-2{
      width: 100%;
      height: 560px;
      border-radius: 40px;
      padding-top: 50px;
      margin-top: 30px;
      background-color: #15191D;
    }
    
    .unlock-2-head{
      width: 100%;
      font-family: Jakarta-extra;
      font-size: 40px;
      padding-left: 5%;
      padding-right: 5%;
      color: #FFFFFF;
    }
    
    .u-2-image{
      width: 554px;
    }
    .u-row-1{
      display: flex;
      width: 90%;
      padding-left: 30px;
    }
    .u-btn-1{
      width: 78px;
      height: 84px;
      border-radius: 0;
      position: absolute;
      top: 0;
      left: 5%;
    }
    .u-btn-1 img{
      width: 100%;
      height: 100%;
    }
    .u-btn-2{
      width: 96px;
      height: 91px;
      border-radius: 0;
      position: absolute;
      top: 0;
      right: 120px;
    }
    .u-btn-3{
      width: 87px;
      height: 83px;
      border-radius: 40px;
      position: absolute;
      top: 0px;
      right: 5%;
    }
    
    .u-btn-4{
      width: 92px;
      height: 88px;
      border-radius: 40px;
      position: absolute;
      bottom: 0;
      left: 8%;
    }
    .u-btn-5{
      width: 91px;
      height: 87px;
      border-radius: 40px;
      position: absolute;
      left: 38%;
      bottom: 0;
    }
    .u-btn-6{
      width: 91px;
      height: 101px;
      border-radius: 40px;
      position: absolute;
      right: 6%;
      bottom: 0;
    }
    
    
    .u-row-2{
      display: flex;
      width: 90%;
      padding-left: 30px;
      margin-top: 5px;
    }
    
    .unlock-2-wrap{
      margin-top: 30px;
      /* display: flex; */
      justify-content: center;
      height: 200px;
      position: relative;
    }

    .c-card-container{
      height: 1550px;
      display: flex;
      padding-left: 0%;
      padding-right: 0%;
      flex-direction: column;
      padding-bottom: 0px;
      position: relative;
      padding-top: 0px;
      justify-content: center;
    }
    .c-card-wrap{
      width: 100%;
      height: 1700px;
      position: relative;
      display: block;
      margin-top: 50px;
    }

    .personalize{
      position: relative;
      height: 400px;
      width: 100%;
      top: 1100px;
      padding-left: 5%;
      padding-right: 5%;
    }
    .seamless{
      left: 0;
      top: 0px;
      width: 100%;
      height: fit-content;
      height: 200px;
      padding-left: 5%;
      padding-right: 5%;
    }  
}